import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpProgressEvent } from '@angular/common/http';
import { User } from 'src/app/user';
import { environment } from 'src/environments/environment';
import { City } from 'src/app/ClassFiles/city';
import { State } from 'src/app/ClassFiles/state';
import { Distirct } from 'src/app/ClassFiles/distirct';
import { ProgramList } from 'src/app/ClassFiles/program-list';
import { Departmentlist } from 'src/app/ClassFiles/departmentlist';
import { Designationlist } from 'src/app/ClassFiles/designationlist';
import { EmployeeNameList } from 'src/app/ClassFiles/employee-name-list';
import { Userr } from 'src/app/ClassFiles/userr';
import { Userrole } from 'src/app/ClassFiles/userrole';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class MasterServiceService {
  city: City[];
  state: State[];
  distirct: Distirct[];
  programList: ProgramList[];
  Departmentlist: Departmentlist[];
  Designationlist: Designationlist[];
  Userrole: Userrole[];
  ACYEAR = new Array();
  YEAR = new Array();
  YEAR20 = new Array();
  YEAR22 = new Array();
  acyr;
  constructor(private http: HttpClient) {

  }
  getacyear() {
    this.ACYEAR = ['2021-2022'];
    return this.ACYEAR;
  }

  getyear() {
    this.YEAR = ['2021', '2022', '2023', '2024'];
    return this.YEAR;
  }

  getyear20() {
    this.YEAR20 = ['2022', '2023', '2024'];
    return this.YEAR20;
  }

  getacyr() {
    this.acyr = ['2020-2021', '2021-2022', '2022-2023', '2023-2024', '2024-2025'];
    return this.acyr;
  }


  login(user: User) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'Login', JSON.stringify(user), { headers: headers });
  }

  InsertOrgnization(NewOrg: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertOrgnization', NewOrg);
  }

  GetStatewiseDistrictList(stname: string) {
    return this.http.get<Distirct[]>(environment.apiUrl + 'JankariPortal/' + 'StatewiseDistrictList/' + stname);
  }
  GetDistrictwiseCityList(dtname: string) {
    return this.http.get<City[]>(environment.apiUrl + 'JankariPortal/' + 'DistrictwiseCityList/' + dtname);
  }
  GetStateList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'StateList');
  }
  GetCityList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'CityList');
  }
  GetDistrictList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'DistrictList');
  }
  getMenus(userId, pgid, roleid, MenuId, typeid) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'Menu/' + '/' + userId + '/' + pgid + '/' + roleid + '/' + MenuId + '/' + typeid);
  }

  GetOrganizationlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetOrganizationlist?whr=' + btoa(where));
  }

  InsertProgram(Newprog: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertProgram', Newprog);
  }
  GetProgramlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetProgramlist?whr=' + btoa(where));
  }

  GetDepartmentlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetDepartmentlist?whr=' + btoa(where));
  }
  GetDesignationtList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetDesignationlist?whr=' + btoa(where));
  }
  InsertDepartment(Newdep: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertDepartment', Newdep);
  }
  InsertDesignation(Newdes: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertDesignation', Newdes);
  }
  DesignationtList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'DesignationList');
  }
  RemoveDesignation(desid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveDesignation/' + desid);
  }

  RemoveProgram(pgid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveProgram/' + pgid);
  }

  RemoveDepartment(depid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveDepartment/' + depid);
  }

  ProgramList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'ProgramList');
  }
  DepartmentList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'DepartmentList');
  }
  OrganizationList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'OrganizationList');
  }
  DesignationList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'DesignationList');
  }
  InsertState(state: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertState', state);
  }
  RemoveState(stname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveState/' + stname);
  }

  InsertDistrict(District: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertDistrict', District);
  }
  RemoveDistrict(dtname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveDistrict/' + dtname);
  }
  Insertholiday(holiday: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'HolidayYearList', holiday);
  }

  InsertCity(city: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertCity', city);
  }
  RemoveCity(ctname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveCity/' + ctname);
  }
  InsertOffice(office: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertOffice', office);
  }
  OfficeList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'OfficeList');
  }
  GetOfficeList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetOfficeList?whr=' + btoa(where));
  }
  InsertYear(year: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertFinanacialYear', year);
  }
  RemoveYear(yearid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveFinancialYear/' + yearid);
  }
  GetyearList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FinanacialYearList');
  }
  InsertPartner(partner: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertPartner', partner);
  }
  GetPartnerlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetPartnerlist?whr=' + btoa(where));
  }

  GetHolidayList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'HolidayYearList');
  }
  InsertHoliday(NewHoliday: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertHoliday', NewHoliday);
  }
  Removeholiday(holid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveHoliday/' + holid);
  }
  InsertRole(role: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertRole', role);
  }
  RemoveUserrole(roleid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveUserrole/' + roleid);
  }

  InsertLeaveCount(leave: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertLeaveCount', leave);
  }
  RemoveLeaveCount(lcid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveLeaveCount/' + lcid);
  }
  LeaveCountList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'LeaveCountList');
  }
  GetUserlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserMasterlist?whr=' + btoa(where));
  }
  OrgnazationwiseProgramList(ogid: string) {
    return this.http.get<ProgramList[]>(environment.apiUrl + 'JankariPortal/' + 'OrgnazationwiseProgramList/' + ogid);
  }

  ProgramwiseDepartmentList(pgid: string) {
    return this.http.get<Departmentlist[]>(environment.apiUrl + 'JankariPortal/' + 'ProgramwiseDepartmentList/' + pgid);
  }
  DepartmentwiseEmployeeList(depid: string) {
    return this.http.get<EmployeeNameList[]>(environment.apiUrl + 'JankariPortal/' + 'DepartmentwiseEmployeeList/' + depid);
  }
  GetEmployeeNameList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'EmployeeNameList');
  }

  EmployeewiseUsername(empid: string) {
    return this.http.get<Userr[]>(environment.apiUrl + 'JankariPortal/' + 'EmployeewiseUsername/' + empid);
  }

  InsertUserMaster(user: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertUserMaster', user);
  }
  GetUserMasterlist(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserMasterlist?whr=' + btoa(where));
  }

  InsertUserProgram(program: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertUserProgram', program);
  }

  ProgramwiseRoleList(pgid: string) {
    return this.http.get<Userrole[]>(environment.apiUrl + 'JankariPortal/' + 'ProgramwiseRoleList/' + pgid);
  }
  UserRoleList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'UserRoleList');
  }

  GetUserProgramDetails(userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserProgramDetails/' + userid);
  }
  EmployeeNameList() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'EmployeeNameList');
  }

  InsertEmployee(employee: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertEmployee', employee);
  }

  InsertEmployeeProgram(Empprogram: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertEmployeeProgram', Empprogram);
  }

  InsertEmployeePromotionNew(data): any {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertEmployeePromotionNew', data);
  }

  DepartmentwiseDesignationList(depid: string) {
    return this.http.get<Designationlist[]>(environment.apiUrl + 'JankariPortal/' + 'DepartmentwiseDesignationList/' + depid);
  }

  InsertEmployeeQualification(EmpQ: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertEmployeeQualification', EmpQ);
  }
  InsertEmployeeReference(EmpR: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertEmployeeReference', EmpR);
  }
  GetEmployeeProgram(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeProgram/' + empid);
  }
  GetUpdateEmployeeProgram(efid: string, empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUpdateEmployeeProgram/' + efid + '/' + empid);
  }
  UpdateEmployeeProgram(efid: string, empid: string, data) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateEmployeeProgram/' + efid + '/' + empid, data);
  }
  GetEmployeeQualification(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeQualification/' + empid);
  }
  GetEmployeeReference(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeReference/' + empid);
  }
  RemoveEmployeeProgram(efid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveEmployeeProgram/' + efid);
  }
  RemoveEmployeeQualification(quliid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveEmployeeQualification/' + quliid);
  }
  RemoveEmployeeReference(refid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveEmployeeReference/' + refid);
  }
  GetEmployeeDetails(empid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeDetails/' + empid);
  }

  UpdateEmployee(empid: string, userid: string, employee: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateEmployee/' + empid + '/' + userid, employee);
  }

  GetRptEmployeeList(empid, roleid, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptEmployeeList/' + empid + '/' + roleid + '?whr=' + where);
  }
  GetEmployeeList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeList?whr=' + btoa(where));
  }
  GetEmployeeListReportstToSRAcc(empid, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeListReportstToSRAcc/empid=' + empid + '?whr=' + where);
  }


  GetStatewiseCityList(stname: string) {
    return this.http.get<City[]>(environment.apiUrl + 'JankariPortal/' + 'StatewiseCityList/' + stname);
  }
  RemoveUserProgram(upid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveUserProgram/' + upid);
  }

  UpdateOffice(offid: string, office: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateOffice/' + offid, office);
  }
  UpdatePartner(partnerid: string, partner: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdatePartner/' + partnerid, partner);
  }
  GetOfficeDetails(offid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetOfficeDetails/' + offid);
  }
  GetPartnerDetails(partnerid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetPartnerDetails/' + partnerid);
  }

  UploadEMPAttendance(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UploadEMPAttendance/' + empid, data);
  }

  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round(100 * event.loaded / event.total);
  }

  EmployeeUploadAttData(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'EmployeeUploadAttData/' + month + '/' + year + '?whr=' + btoa(where));
  }

  UpdateEMPAttendance(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateEMPAttendance/', data);
  }

  DepartmentwiseEmpAttendanceList(whr) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'DepartmentwiseEmpAttendanceList?whr=' + btoa(whr));
  }

  GetEmployeeReport(where) {

    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptEmployee?whr=' + btoa(where));
  }

  EmployeeAttDetailsList(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'EmployeeAttDetailsList/' + month + '/' + year + '?whr=' + btoa(where));
  }

  EmployeeAttDetailsData(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'EmployeeAttDetailsData/' + month + '/' + year + '?whr=' + btoa(where));
  }
  GetEmployeeAttGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpAttAllSumGraphData?whr=' + btoa(where));
  }

  FillDropDown(tablename, column1, column2, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillDropDown?tablename=' + tablename + '&column1=' + btoa(column1) + '&column2=' + btoa(column2) + '&whr=' + btoa(where));
  }
  GetReportstoEmp(desid) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetReportstoEmp/' + desid);
  }
  GetEmpAttAllSumlineGraph(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpAttAllSumlineGraph/' + month + '/' + year + '?whr=' + btoa(where));
  }

  GetEmpAttAllSumPieGraph(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpAttAllSumPieGraph/' + month + '/' + year + '?whr=' + btoa(where));
  }
  GetEmpAttAllSumbarGraph(empcode: string, month: string, year: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpAttAllSumbarGraph/' + empcode + '/' + month + '/' + year);
  }

  GetEmpAttAllSumbarGraphback(empcode: string, month: string, year: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpAttAllSumbarGraphback/' + empcode + '/' + month + '/' + year);
  }
  GetProfile(userid: string, pgid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetProfile/' + userid + '/' + pgid);
  }

  // UpdatePassword(Newpass: any) {

  //   return this.http.post(environment.apiUrl+"JankariPortal/" + "UpdatePassword", Newpass);
  // }
  UpdatePassword(userid: string, otp: string, data: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateOTPPassword/' + userid + '/' + otp, data);
  }
  GetSalaryList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryList?whr=' + btoa(where));
  }
  GetOrgid(userid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetOrgid/' + userid);
  }
  CheckAttendace(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'CheckAttendace/' + month + '/' + year + '?whr=' + btoa(where));

  }

  CheckAttendaceList(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'CheckAttendaceList/' + month + '/' + year + '?whr=' + btoa(where));

  }


  AddEMPAttendance(month: number, year: number, empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddEMPAttendance/' + month + '/' + year + '/' + empid, data);
  }

  AddSalary(userid: string, pgid: string, depid: string, month: string, year: string, fineyear: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddSalary/' + userid + '/' + pgid + '/' + depid + '/' + month + '/' + year + '/' + fineyear, data);
  }
  AddEmployeeLeave(lcid: any) {

    return this.http.post(environment.apiUrl + 'Employee/' + 'AddEmployeeLeave', lcid);
  }
  GetEmployeeLeaveList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLeaveList?whr=' + btoa(where));
  }

  GetEmployeeLeaveData(i) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLeaveData/' + i);
  }
  GetEmployeeLeaveDetails(i) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLeaveDetails/' + i);
  }
  ApproveEmployeeLeave(lcid: string, empid: string, escalateid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ApproveEmployeeLeave/' + lcid + '/' + empid + '/' + escalateid, data);
  }
  GetRoleDetails(roleid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRoleDetails/' + roleid);
  }
  GetMenuForRole(roleid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetMenuForRole/' + roleid);
  }
  GetMenuNoRole(roleid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetMenuNoRole/' + roleid);
  }
  Assignmenutorole(roleid: string, employee: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'Assignmenutorole/' + roleid, employee);
  }

  RemoveRoleMenu(roleid: string, menuid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveRoleMenu/' + roleid + '/' + menuid);
  }

  InsertUserActivity(user: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertUserActivity', user);
  }
  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }
  GetUserActivityList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserActivityList?whr=' + btoa(where));
  }
  postFile(empid: string, fileToUpload: File) {
    const formData: FormData = new FormData();

    formData.append('Image', fileToUpload);

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UploadEMPPhoto/' + empid, formData);

  }
  // postFile( empid: string,fileToUpload: File) {
  //   const formData: FormData = new FormData();
  // formData.append('Image', fileToUpload);

  // return this.http.post(environment.apiUrl1 +"UploadImage/"+empid, formData);
  //  }

  GetProfileImage(empid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeProfiePic/' + empid);
  }
  GetSalAlertList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalAlertList?whr=' + btoa(where));
  }


  GetEmpApprovalSalaryData(where: string, month: string, year: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpApprovalSalaryData/' + month + '/' + year + '?whr=' + btoa(where));
  }

  InsertEmployeeDocument(empid: string, year: any, data: any) {

    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertEmployeeDocument/' + empid + '/' + year, data);
  }


  ApproveNotgenratedSalary(Salid: string, userid: any, data: any) {

    return this.http.post(environment.apiUrl + 'Employee/' + 'ApproveNotgenratedSalary/' + Salid + '/' + userid, data);
  }
  ApproveSalary(Salid: string, userid: any, data: any) {

    return this.http.post(environment.apiUrl + 'Employee/' + 'ApproveSalary/' + Salid + '/' + userid, data);
  }
  GetSalaryEmail(userid: string, month: string, year: string, finyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryEmail/' + userid + '/' + month + '/' + year + '/' + finyear);
  }
  GetDownloadICICISalaryList(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDownloadICICISalaryList?whr=' + btoa(where));
  }
  GetEmployeeDocument(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeDocumentNew/' + empid);
  }
  GetEmployeePromotionDetailNew(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotionDetailNew/' + empid);
  }
  GetEmployeePromotionDetNew(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotionDetNew/' + empid);
  }
  RemoveEmployeeDocument(empid: string, financialyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'RemoveEmployeeDocument/' + empid + '/' + financialyear);
  }
  GetRptSalayProgramWise(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSalayProgramWise?whr=' + btoa(where));
  }
  GetRptGrossSalary(styear: string, endyear: string, where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptGrossSalary/' + styear + '/' + endyear + '?whr=' + btoa(where));
  }
  GetEmployeeAttendanceSummary(frommonth: string, tomonth: string, fromyear: string, toyear: string, where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeAttendanceSummary/' + frommonth + '/' + tomonth + '/' + fromyear + '/' + toyear + '?whr=' + btoa(where));
  }
  GetRptEmployeeMonthlyAttendance(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeMonthlyAttendance?whr=' + btoa(where));
  }

  GetRptSalaryMod(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSalaryMod?whr=' + btoa(where));
  }

  GetRptSalaryICICIBank(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSalaryICICIBank?whr=' + btoa(where));
  }
  GetRptSalary(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSalary?whr=' + btoa(where));
  }

  GetRptSalaryWithHrs(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSalaryWithHrs?whr=' + btoa(where));
  }

  GetRptPartnerMonthlySalary(stdt: string, endt: string, where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptPartnerMonthlySalary/' + stdt + '/' + endt + '?whr=' + btoa(where));
  }

  GetSalaryDetailsData(salid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryDetailsData/' + salid + '?whr=' + btoa(where));
  }
  HomeSummary() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'HomeSummary');
  }
  BirthdayNotification() {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'BirthdayNotification');
  }

  SendBirthdayWish(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'SendBirthdayWish/' + empid, data);
  }
  GetUserProgramLoginList(where) {

    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserProgramLoginList?whr=' + btoa(where));
  }

  GetProgramWiseEmployeeRole(pgid: string, userid: string) {

    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetProgramWiseEmployeeRole/' + pgid + '/' + userid);
  }
  FillEmpByDepDes(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillEmpByDepDes?whr=' + btoa(where));
  }

  FillCenterState(us, ur, ogid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillCenterState?us=' + us + '&ur=' + ur + '&ogid=' + ogid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  GetName(table: string, col: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetName?table=' + table + '&col=' + btoa(col) + '&whr=' + btoa(where));
  }
  FillCenterCityIDI(us, ur, ogid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'FillCenterCityIDI?us=' + us + '&ur=' + ur + '&ogid=' + ogid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }


  AddEmployeeFavourite(fev: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddEmployeeFavourite', fev);
  }
  EmployeeFavouriteList(userid: string, pgid: string, roleid: string, url: string, favname: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'EmployeeFavouriteList/' + userid + '/' + pgid + '/' + roleid + '/' + url + '/' + favname);
  }
  EmployeeAllFavouriteList(userid: string, pgid: string, roleid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'EmployeeAllFavouriteList/' + userid + '/' + pgid + '/' + roleid);
  }

  GetUserDailyTaskList(where) {

    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserDailyTaskList?whr=' + btoa(where));
  }
  GetUserDailyReportList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserDailyReportList?whr=' + btoa(where));
  }

  AddUserDailyTask(userid: string, task: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddUserDailyTask/' + userid, task);
  }

  GetSalarySlipRequestList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalarySlipRequestList?whr=' + btoa(where));
  }
  GetSalarySlipNewList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalarySlipNewList?whr=' + btoa(where));
  }
  GetHRSalarySlipList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetHRSalarySlipList?whr=' + btoa(where));
  }
  AddsalarySlip(empid: string, month: number, year: number) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'AddsalarySlip/' + empid + '/' + month + '/' + year);
  }

  GetApprovedSalaryList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetApprovedSalaryList?whr=' + btoa(where));
  }

  GetApprovedSalaryDetailsData(userid: string, month: string, year: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetApprovedSalaryDetailsData/' + userid + '/' + month + '/' + year);
  }
  GetSalarySlipPrintData(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalarySlipPrintData?whr=' + btoa(where));
  }

  GetNumbersToWords(inputNumber: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetNumbersToWords/' + inputNumber);
  }

  GetHideButton(roleid: string, pgid: string, pagename: string, buttontype: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetHideButton/' + roleid + '/' + pgid + '/' + pagename + '/' + buttontype);
  }

  GetMyJankariEmpAttList(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetMyJankariEmpAttList?whr=' + btoa(where));
  }

  DownloadsalarySlipprint(task: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'DownloadsalarySlipprint/', task);
  }

  ForgotPassword(username: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'ForgotPassword', username);
  }

  CheckOTP(data: any) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'CheckOTP/', data);
  }

  GetEmployeeSalaryBreakDown(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeSalaryBreakDown/' + empid);
  }


  GetDepartmentwiseEMPCountPieGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDepartmentwiseEMPCountPieGraph?whr=' + btoa(where));
  }

  GetPartnerwiseEMPCountPieGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPartnerwiseEMPCountPieGraph?whr=' + btoa(where));
  }
  GetPartnerwiseEMPSalaryPieGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPartnerwiseEMPSalaryPieGraph?whr=' + btoa(where));
  }

  GetDepartmentwiseEMPSalaryPieGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDepartmentwiseEMPSalaryPieGraph?whr=' + btoa(where));
  }
  InsertEmployeeLoan(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertEmployeeLoan/', data);
  }
  GetEmployeeAttendanceSummaryList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeAttendanceSummaryList?whr=' + btoa(where));
  }

  GetEmployeeLoanList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLoanList?whr=' + btoa(where));
  }
  GetEmployeeLoanDetail(userId: string): any {
    console.log(userId);
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLoanDetail/' + userId);
  }
  GetLoanDetail(loanid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetLoanDetail/' + loanid);
  }

  ApproveEmployeeLoan(empid: string, loanid: any, data: any) {

    return this.http.post(environment.apiUrl + 'Employee/' + 'ApproveEmployeeLoan/' + empid + '/' + loanid, data);
  }
  GetRptProgramwiseEmployeeSalarySummary(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptProgramwiseEmployeeSalarySummary/' + month + '/' + year + '?whr=' + btoa(where));
  }
  GetHeadCountEmployeeList(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetHeadCountEmployeeList?whr=' + btoa(where));
  }
  GetRptDepartmentwiseEmployeeSalarySummary(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDepartmentwiseEmployeeSalarySummary/' + month + '/' + year + '?whr=' + btoa(where));
  }
  GetRptDesignationwiseEmployeeSalarySummary(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDesignationwiseEmployeeSalarySummary/' + month + '/' + year + '?whr=' + btoa(where));
  }
  GetRptPaidbyschoolEmployeeSalarySummary(month: string, year: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptPaidbyschoolEmployeeSalarySummary/' + month + '/' + year + '?whr=' + btoa(where));
  }

  GetRptAPPEmployeeAttendance(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendance?whr=' + btoa(where));
  }
  GetRptEmployeeMonthlysalary(month: number, year: number, finyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeMonthlysalary/' + month + '/' + year + '/' + finyear + '?whr=' + btoa(where));
  }
  GetInterviewApplicationList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetInterviewApplicationList?whr=' + where);
  }
  GetInterviewApplicationDetails(jaid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetInterviewApplicationDetails/' + jaid);
  }
  GetInterviewQualification(jaid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetInterviewQualification/' + jaid);
  }
  GetRptAPPEmployeeAttendanceNotMarked(date: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceNotMarked?date=' + date + '&whr=' + btoa(where));
  }
  UploadEmployeeDocument(empid: string, fineyear: string, documenttype: string, fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('Image', fileToUpload);
    return this.http.post(environment.apiUrl + 'Employee/' + 'UploadEmployeeDocument/' + empid + '/' + fineyear + '/' + documenttype, formData);
  }
  UpdateInterviewApplication(jaid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateInterviewApplication/' + jaid, data);
  }

  VacancyList() {
    return this.http.get(environment.apiUrl + 'Employee/' + 'VacancyList');
  }
  GetJobApplicationList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetJobApplicationList?whr=' + btoa(where));
  }
  GetJobApplicationDetails(jaid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetJobApplicationDetails/' + jaid);
  }
  GetEmployeeExperience(jaid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeExperience/' + jaid);
  }

  InsertEmployeePromotion(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertEmployeePromotion/', data);
  }
  GetNewGrossSalaryDetail(empid: number, grosssalary: number): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetNewGrossSalaryDetail/' + empid + '/' + grosssalary);
  }
  GetEmployeePromotion(empid: number): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotion/' + empid);
  }
  GetRptEmployeePendingSalarySummary(where) {

    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeePendingSalarySummary?whr=' + btoa(where));
  }

  GetEmployeeESICPFSummaryList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeESICPFSummaryList?whr=' + btoa(where));
  }
  GetSalaryBrakupList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryBrakupList?whr=' + btoa(where));
  }

  AddDownloadsalarySlip(empid: string, depid: string, pgid: string, year: string, finyear: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddDownloadsalarySlip/' + empid + '/' + depid + '/' + pgid + '/' + year + '/' + finyear, data);
  }
  ApprovesalarySlip(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ApprovesalarySlip/' + empid, data);
  }
  GetAppoinmentLetterDetail(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetAppoinmentLetterDetail/' + empid);
  }
  GetInactiveAppoinmentLetterDetail(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetInactiveAppoinmentLetterDetail/' + empid);
  }
  GetExperienceLetterDetail(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetExperienceLetterDetail/' + empid);
  }
  GetJD(desid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetJD/' + desid);
  }
  GetDSEmployeeList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeList?whr=' + btoa(where));
  }

  InsertDSEmployee(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertDSEmployee/' + userid, data);
  }

  GetDSAttendance(searchdate, date, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSAttendance?attdate=' + btoa(searchdate) + '&date=' + btoa(date) + '&whr=' + btoa(where));
  }
  AddDSAttendance(dsbatch: string, userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddDSAttendance/' + dsbatch + '/' + userid, data);
  }
  GetDSAttendanceList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSAttendanceList?whr=' + btoa(where));
  }
  InsertFeedBack(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertFeedBack', data);
  }
  GetRptDSBeneficiary(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSBeneficiary?whr=' + btoa(where));
  }

  GetDSFeedbackSummaryList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSFeedbackSummaryList?whr=' + btoa(where));
  }
  GetDSFeedbackDetailsList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSFeedbackDetailsList?whr=' + btoa(where));
  }
  GetRptDSAttendanceSummary(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSAttendanceSummary?whr=' + btoa(where));
  }
  GetRptDSBeneficiarySummary(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptDSBeneficiarySummary?whr=' + btoa(where));
  }
  GetDSBeneficiaryDetail(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetDSBeneficiaryDetail?whr=' + btoa(where));
  }

  GetDSEmployeeCountLineGraph(where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeCountLineGraph?whr=' + btoa(where));
  }

  GetPartnerwiseDsEmployeePieGraph(where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPartnerwiseDsEmployeePieGraph?whr=' + btoa(where));
  }
  GetDigitalEmployeeDetail(dsempid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalEmployeeDetail/' + dsempid);
  }
  UpdateDigitalEmployeeDetail(dsempid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateDigitalEmployeeDetail/' + dsempid, data);
  }
  RemoveDigitalEmployee(dsempid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'RemoveDigitalEmployee/' + dsempid);
  }
  GetRptDSBeneficiaryTL(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSBeneficiaryTL?whr=' + btoa(where));
  }
  GetRptDSBeneficiarySancharak(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSBeneficiarySancharak?whr=' + btoa(where));
  }
  GetDSEmployeeStatewiseUserCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeStatewiseUserCount?whr=' + btoa(where));
  }

  GetDSEmployeeStatewiseBenificiaryCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeStatewiseBenificiaryCount?whr=' + btoa(where));
  }

  GetDSEmployeePlanVsActual(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeePlanVsActual?whr=' + btoa(where));
  }

  GetDSAttendanceDetail(attdate, debatch, createby) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSAttendanceDetail?attdate=' + attdate + '&debatch=' + debatch + '&createby=' + createby);

  }

  UpdateDSAttendance(debatch: string, createby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateDSAttendance/' + debatch + '/' + createby, data);
  }
  GetDSEmployeeStatePlanVsActual(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeStatePlanVsActual?whr=' + btoa(where));
  }
  InsertCourseFeedBack(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertCourseFeedBack', data);
  }
  GetDigitalCourseFeedbackList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalCourseFeedbackList?whr=' + btoa(where));
  }
  GetRptDigitalCourseFeedback(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDigitalCourseFeedback?whr=' + btoa(where));
  }

  GetDSEmployeeCoursewiseBeniCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeCoursewiseBeniCount?whr=' + btoa(where));
  }

  GetDSEmployeeFeedbackgraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeFeedbackgraph?whr=' + btoa(where));
  }
  GetDSEmployeeFeedbackgraphData(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeFeedbackgraphData?whr=' + btoa(where));
  }


  GetDSEmployeeGenderwiseBeneficiarygraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeGenderwiseBeneficiarygraph?whr=' + btoa(where));
  }

  GetDSEmployeeAgeGroupBeneficiarygraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeAgeGroupBeneficiarygraph?whr=' + btoa(where));
  }

  GetDigitalCourseFeedback(dsempid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalCourseFeedback/' + dsempid);
  }
  UpdateDigitalCourseFeedback(dsempid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateDigitalCourseFeedback/' + dsempid, data);
  }
  GetDSEmployeePerformanceList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeePerformanceList?whr=' + btoa(where));
  }
  InsertDSEmployeePerformance(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertDSEmployeePerformance/', data);
  }
  GetTempCountDetail(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetTempCountDetail/' + empid);
  }

  GetPCCourseFeedback(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPCCourseFeedback?whr=' + btoa(where));
  }
  GetTLCourseFeedback(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetTLCourseFeedback?whr=' + btoa(where));
  }


  GetPCCourseFeedbackDetail(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPCCourseFeedbackDetail?whr=' + btoa(where));
  }



  GetTLCourseFeedbackDetail(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetTLCourseFeedbackDetail?whr=' + btoa(where));
  }




  GetDSVolenteerStatePlanVsActual(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSVolenteerStatePlanVsActual?whr=' + btoa(where));
  }
  GetCoordinatorwiseVoleenterBenificiaryCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetCoordinatorwiseVoleenterBenificiaryCount?whr=' + btoa(where));
  }

  GetDigitalCourseFeedbackGraphical(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalCourseFeedbackGraphical?whr=' + btoa(where));
  }
  GetSalaryNotGenratedSalaryAlert(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryNotGenratedSalaryAlert?whr=' + btoa(where));
  }
  GetRptDSBeneficiaryAssessment(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSBeneficiaryAssessment?whr=' + btoa(where));
  }
  GetEmpSalary(month: string, year: string, finyear: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmpSalary/' + month + '/' + year + '/' + finyear + '?whr=' + btoa(where));

  }
  UpdateGridSalary(month: string, year: string, fineyear: string, userid: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'UpdateGridSalary/' + month + '/' + year + '/' + fineyear + '/' + userid + '?whr=' + btoa(where));
  }
  GetDSCoursewisecompetencyGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSCoursewisecompetencyGraph?whr=' + btoa(where));
  }
  GetDigitalPrimaryCertificationList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalPrimaryCertificationList?whr=' + btoa(where));
  }
  GetDigitalVolenteerCertificateList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDigitalVolenteerCertificateList?whr=' + btoa(where));
  }
  FillDropDownUserwise(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwise?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }
  FillDropDownUserwiseMyEschool(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwiseMyEschool?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }
  GetStateHeadBenificaryCountSummary(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetStateHeadBenificaryCountSummary?whr=' + btoa(where));
  }
  GetStateHeadBenificaryCountList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetStateHeadBenificaryCountList?whr=' + btoa(where));
  }
  GetRptDigitalCourseFeedbackCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDigitalCourseFeedbackCount?whr=' + btoa(where));
  }

  GetRptDSAssessmentPieGraph(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSAssessmentPieGraph?whr=' + btoa(where));
  }

  GetDSAnalysisCount(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSAnalysisCount?whr=' + btoa(where));
  }

  GetRptDSPartnerwiseAttendance(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSPartnerwiseAttendance?whr=' + btoa(where));
  }
  GetRptDSPartnerwiseAssessment(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSPartnerwiseAssessment?whr=' + btoa(where));
  }
  GetCoursewiseDSAssessmentBargraph(state: string, batname: string, academicyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetCoursewiseDSAssessmentBargraph/' + state + '/' + batname + '/' + academicyear);
  }
  GetDSEmployeeCoursewiseBeniCountAnalysis(state: string, batname: string, academicyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeCoursewiseBeniCountAnalysis/' + state + '/' + batname + '/' + academicyear);
  }
  GetDSEmployeeAgeGroupBeneficiarygraphAnalysis(state: string, batname: string, academicyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeAgeGroupBeneficiarygraphAnalysis/' + state + '/' + batname + '/' + academicyear);
  }
  GetRptDSAssessmentPieGraphonepageAnalysis(state: string, batname: string, academicyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptDSAssessmentPieGraphonepageAnalysis/' + state + '/' + batname + '/' + academicyear);
  }

  GetDSEmployeeStateCitywiseData(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeStateCitywiseData?whr=' + btoa(where));
  }

  GetDSEmployeeCoursewiseBeniCity(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeCoursewiseBeniCity?whr=' + btoa(where));
  }

  GetDSEmployeeAgeGroupBeneficiaryCity(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeAgeGroupBeneficiaryCity?whr=' + btoa(where));
  }
  GetDSEmployeeGenderwiseBeneficiaryCity(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetDSEmployeeGenderwiseBeneficiaryCity?whr=' + btoa(where));
  }

  GetParentBatchList(where) {
    return this.http.get(environment.apiUrl + 'Center/' + 'GetParentBatchList?whr=' + btoa(where));
  }
  AddParentBatch(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Center/' + 'AddParentBatch/' + userid, data);
  }

  RemoveUserDailyTask(reportid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemoveUserDailyTask/' + reportid);
  }

  GetSalarySlipDataNewInativeList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalarySlipDataNewInativeList?whr=' + btoa(where));
  }

  GetSalarySlipInactivePrintData(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalarySlipInactivePrintData?whr=' + btoa(where));
  }

  GetUserDailyReportCalenderView(empid: string): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetUserDailyReportCalenderView/' + empid);
  }

  GetMonthwiseAttendanceReport(month: string, year: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetMonthwiseAttendanceReport/' + month + '/' + year + '?whr=' + btoa(where));

  }

  GetIDIDigitalCourseFeedbackList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetIDIDigitalCourseFeedbackList?whr=' + btoa(where));
  }

  GetMonthwiseAttendanceUserwiseReport(month: string, year: string, pgid: string, userid: string, where): any {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetMonthwiseAttendanceUserwiseReport/' + month + '/' + year + '/' + pgid + '/' + userid + '?whr=' + btoa(where));

  }

  EmployeeAppointmentDownload(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'EmployeeAppointmentDownload/' + empid, data);
  }

  // State Head wise api


  GetRptAPPEmployeeAttendanceNotMarkedStateHead(userid: string, role: string, pgid: string, date: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceNotMarkedStateHead/' + userid + '/' + role + '/' + pgid + '?date=' + date + '&whr=' + where);
  }

  InsertATLParticipationEval(studentid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Student/' + 'InsertATLParticipationEval/' + studentid, data);
  }

  GetRptAPPEmployeeAttendanceStateHead(userid: string, role: string, pgid: string, startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceStateHead/' + userid + '/' + role + '/' + pgid + '?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }

  GetRptAPPEmployeeAttendanceWeekly(startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceWeekly?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }

  AddSafeGaurdingFeedback(academicyear: string, feed: any,) {

    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddSafeGaurdingFeedback/' + academicyear, feed);
  }

  GetFeedbackResult(empid: number) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetFeedbackResult/' + empid);
  }
  GetRptSafegaurdFeedback(where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptSafegaurdFeedback?whr=' + btoa(where));
  }
  AddPartnerDetail(partnerid: string, partner: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddPartnerDetail/' + partnerid, partner);
  }
  RemovePartnerDetail(pdid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'RemovePartnerDetail/' + pdid);
  }

  GetPartnerDetail(partnerid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetPartnerDetail/' + partnerid);
  }

  GetAttendanceforSalary(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetAttendanceforSalary?whr=' + btoa(where));
  }

  AddAttendanceforSalary(updateby, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddAttendanceforSalary/' + updateby, data);
  }


  GetAttendanceforSalaryList(whr) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetAttendanceforSalaryList?whr=' + btoa(whr));
  }
  UpdateAttendanceforSalary(data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateAttendanceforSalary', data);
  }
  GetAttendanceforSalaryView(whr, month, year) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetAttendanceforSalaryView?whr=' + btoa(whr) + '&month=' + month + '&year=' + year);
  }
  GetRptEmployeeOHPCWise(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptEmployeeOHPCWise?whr=' + btoa(whr));
  } GetSalAttendanceDetail(depid: string, month: number, year: number, finyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalAttendanceDetail/' + depid + '/' + month + '/' + year + '/' + finyear);
  }
  GetEMPSalAttendanceDetail(empid: string, salid: string, month: number, year: number, finyear: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEMPSalAttendanceDetail/' + empid + '/' + salid + '/' + month + '/' + year + '/' + finyear);
  }
  UpdateSalaryData(userid: string, salid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'UpdateSalaryData/' + userid + '/' + salid, data);
  }
  FillDropDownUserwisewithInactive(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwisewithInactive?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }


  GetRptNewEmployee(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptNewEmployee?whr=' + btoa(where));
  }

  GetRptInactiveEmployee(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptInactiveEmployee?whr=' + btoa(where));
  }


  GetEmpTrainingList(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmpTrainingList/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetUpdateEmployeeTrainingDetail(etid: String) {
    return this.http.get(environment.apiUrl + "JankariPortal/" + "GetUpdateEmployeeTrainingDetail?etid=" + etid);
  }

  UpdateEmpTraining(etid: string, createdby: string, data) {
    return this.http.post(environment.apiUrl + "JankariPortal/" + "UpdateEmpTraining?etid=" + etid + '&createdby=' + createdby, data);
  }

  GetRptEmployeeTrainingDetail(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptEmployeeTrainingDetail/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptEmployeeTrainingSummary(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptEmployeeTrainingSummary/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }


  UpdatePersonalInfo(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdatePersonalInfo/' + userid, data);
  }

  UpdateContactInfo(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateContactInfo/' + userid, data);
  }
  GetMarkInNotification(empid: String) {
    return this.http.get(environment.apiUrl + "Employee/" + "GetMarkInNotification/" + empid);
  }
  AddEmployeeAtt(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddEmployeeAtt/' + userid, data);
  }


  GetRptEmployeeAssociate(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeAssociate?whr=' + btoa(where));
  }

  GetPCCourseFeedbackAssociate(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPCCourseFeedbackAssociate?whr=' + btoa(where));
  }

  GetTLCourseFeedbackAssociate(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetTLCourseFeedbackAssociate?whr=' + btoa(where));
  }

  GetRptEmployeeTrainingDetailAssociate(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeTrainingDetailAssociate/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetRptEmployeeTrainingSummaryAssociate(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeTrainingSummaryAssociate/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }

  GetEmployeeTrainingDetailList(empid: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeTrainingDetailList/' + empid + '/' + pgid);
  }
  GetRptAPPEmployeeAttendanceStateHeadTL(userid: string, role: string, pgid: string, startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceStateHeadTL/' + userid + '/' + role + '/' + pgid + '?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }

  GetRptAPPEmployeeAttendanceNotMarkedStateHeadTL(userid: string, role: string, pgid: string, date: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceNotMarkedStateHeadTL/' + userid + '/' + role + '/' + pgid + '?date=' + date + '&whr=' + btoa(where));
  }

  AddEmployeeTraining(tdate: string, tprogram: string, tby: string, createby: string, pgid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddEmployeeTraining?tdate=' + tdate + '&tprogram=' + tprogram + '&tby=' + tby + '&creatdeby=' + createby + '&pgid=' + pgid, data);
  }

  CreateUser(logindata: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'CreateUser', logindata);
  }

  GetRptEmployeeVisit(userid: string, role: string, pgid: string, whr) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeVisit/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(whr));
  }

  // trainee start

  GetTraineeEmployeeAssignmentDetailList(userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeEmployeeAssignmentDetailList/' + userid);
  }

  GetRptTraineeEmployeeAssignment(userid: string, role: string, whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeEmployeeAssignment/' + userid + '/' + role + '?whr=' + btoa(whr));
  }

  GetTraineeEmployeeAssignmentList(userid: string, role: string, whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeEmployeeAssignmentList/' + userid + '/' + role + '?whr=' + btoa(whr));
  }
  // GetTraineeEmployeeAssignmentList(userid:string, role:string,){
  //   return this.http.get(environment.apiUrl+ 'JankariPortal/'+ 'GetTraineeEmployeeAssignmentList/'+ userid +'/' + role +'/' +topic + '/' + date);
  // }

  AddTraineeEmployeeAssignment(date: String, topic: string, creatdeby: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddTraineeEmployeeAssignment?date=' + date + '&topic=' + topic + '&creatdeby=' + creatdeby, data);
  }

  GetUpdateTraineeEmployeeAssignmentDetail(teaid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUpdateTraineeEmployeeAssignmentDetail?teaid=' + teaid)
  }

  UpdateTraineeEmployeeAssignment(teaid: string, createdby: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateTraineeEmployeeAssignment?teaid=' + teaid + '&createdby=' + createdby, data);
  }


  // trainee end



  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }
  InsertUserInfo(UserDeviceInfo: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertUserInfo', UserDeviceInfo);
  }
  EmployeeAsessmentReport() {
    return this.http.get(environment.apiUrl + 'Employee/' + 'EmployeeAsessmentReport');
  }

  AddTraineeEmployeeFeedback(createdby: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddTraineeEmployeeFeedback/' + createdby, data)
  }


  GetTraineeObervationList(userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeObervationList/' + userid);
  }

  GetTraineeObervation(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeObervation?whr=' + btoa(whr));
  }

  AddTraineeObervation(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddTraineeObervation?userid=' + userid, data)
  }

  GetTraineeObervationData(obid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeObervationData/' + obid);
  }

  UpdateTraineeObervation(obid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateTraineeObervation/' + obid, data);
  }

  GetTraineeEmployee(empid: string, date: string, training: string, day: string, academicyear: string, pgid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeEmployee/' + empid + '/' + date + '/' + training + '/' + day + '/' + academicyear + '/' + pgid);
  }

  GetTraineeAttendanceList(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeAttendanceList?whr=' + btoa(whr));
  }

  AddTraineeAttendance(date: string, training: string, day: string, userid: string, academicyear: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddTraineeAttendance/' + date + '/' + training + '/' + day + '/' + userid + '/' + academicyear, data);
  }
  GetTraineeAttendanceData(trattid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTraineeAttendanceData/' + trattid);
  }
  UpdateTraineeAttendance(trattid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'UpdateTraineeAttendance/' + trattid, data);
  }
  GetRptTraineeAttendance(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeAttendance?whr=' + btoa(whr));
  }
  GetRptTraineeEmployeeFeedback(whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeEmployeeFeedback?whr=' + btoa(whr));
  }
  GetRptTraineeObervationData(userid: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeObervationData/' + userid);
  }
  GetRptTraineeEmployeeSoftskillCompetancy(userid: string, role: string, whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeEmployeeSoftskillCompetancy/' + userid + '/' + role + '?whr=' + btoa(whr));
  }

  GetRptTraineeFinalAssessment(userid: string, role: string, whr) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetRptTraineeFinalAssessment/' + userid + '/' + role + '?whr=' + btoa(whr));
  }

  ActiveEmployee(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ActiveEmployee/' + empid, data);
  }
  ActiveEmployees(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ActiveEmployees/' + empid, data);
  }
  SahyogiEvalCompetency(partnerid: string, acyear: string, pgid: string, examtype: string, standard: string) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiEvalCompetency/' + partnerid + '/' + acyear + '/' + pgid + '/' + examtype + '/' + standard)
  }

  SahyogiFcEvalCompetency(partnerid: string, acyear: string, pgid: string, examtype: string, standard) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'SahyogiFcEvalCompetency/' + partnerid + '/' + acyear + '/' + pgid + '/' + examtype + '/' + standard);
  }

  GetPartnerFCStudCount(where) {
    return this.http.get(environment.apiUrl + 'Partner/' + 'GetPartnerFCStudCount?whr=' + btoa(where));
  }

  GetActiveEmployeeFor(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetActiveEmployeeFor/' + empid);
  }

  GetRptSafegaurdingFeedback(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSafegaurdingFeedback?whr=' + btoa(where));
  }
  GetEmployeeLeaveReportFinYear(finyear: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLeaveReportFinYear/' + finyear + '?whr=' + btoa(where))
  }
  GetEmployeeLeaveReportReportingWiseFinYear(finyear: string, empid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeLeaveReportReportingWiseFinYear/' + finyear + '/' + empid + '?whr=' + btoa(where))
  }
  GetCentWiseSancharak(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetCentWiseSancharak?whr=' + btoa(where));
  }
  FillDropDownUserwiseCyber(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwiseCyber?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }

  getyear22() {
    this.YEAR22 = ['2024'];
    return this.YEAR22;
  }
  GetEmployeePromotionDetail(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromotionDetail/' + empid);
  }
  GetRptEmployeeAssociateEnglish(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeAssociateEnglish?whr=' + btoa(where));
  }
  GetRptAssignSancharakEmployee(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAssignSancharakEmployee/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptSchoolAssignSancharakEmployee(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSchoolAssignSancharakEmployee/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetSalaryDetailsDataNew(salid: string, userid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetSalaryDetailsDataNew/' + salid + '/' + userid);
  }
  GetRptEmployeeMonthlyAttendanceOH(userid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeMonthlyAttendanceOH/' + userid + '?whr=' + btoa(where));
  }
  GetRptAPPEmployeeAttendanceStateHeadPC(userid: string, role: string, pgid: string, startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceStateHeadPC/' + userid + '/' + role + '/' + pgid + '?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }
  GetRptSafegaurdingFeedbackTL(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSafegaurdingFeedbackTL/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptSafegaurdingFeedbackPC(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSafegaurdingFeedbackPC/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptSafegaurdingFeedbackOH(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSafegaurdingFeedbackOH/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptSafegaurdingFeedbackAssociate(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSafegaurdingFeedbackAssociate/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetRptAPPEmployeeAttendanceWeeklyDetail(startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceWeeklyDetail?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }
  GetRptAPPEmployeeAttendanceDetailOH(userid: string, role: string, pgid: string, startdate: string, enddate: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceDetailOH/' + userid + '/' + role + '/' + pgid + '?date=' + startdate + '&date1=' + enddate + '&whr=' + btoa(where));
  }
  GetRptEmployeeLeave(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptEmployeeLeave?whr=' + btoa(where));
  }
  GetRejoinEmployeeList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRejoinEmployeeList?whr=' + btoa(where));
  }
  GetEmployeeVisitLogList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeVisitLogList?whr=' + btoa(where));
  }
  GetVisitList2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetVisitList2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetVisitListCEO2023(userid: string, role: string, pgid: string, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetVisitListCEO2023/' + userid + '/' + role + '/' + pgid + '?whr=' + btoa(where));
  }
  GetVisitFeedbackDetails(id) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetVisitFeedbackDetails/' + id);
  }
  InsertVisitFeedback(userid: string, data): any {
    return this.http.post(environment.apiUrl + 'Employee/' + 'InsertVisitFeedback/' + userid, data);
  }
  GetVisitFeedbackDesabledData(id) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetVisitFeedbackDesabledData/' + id);
  }
  GetTrainingExamSummary(userid: string, role: string, pgid: string, training: string, type: string, language: string, acyear: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTrainingExamSummary/' + userid + '/' + role + '/' + pgid + '/' + training + '/' + type + '/' + language + '/' + acyear + '?whr=' + btoa(where));
  }
  GetTrainingExamSummaryCEO(userid: string, role: string, pgid: string, training: string, type: string, language: string, acyear: string, where) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetTrainingExamSummaryCEO/' + userid + '/' + role + '/' + pgid + '/' + training + '/' + type + '/' + language + '/' + acyear + '?whr=' + btoa(where));
  }
  GetUserExam(empid: string, exam: string, type: string, language: string) {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetUserExam/' + empid + '/' + exam + '/' + type + '/' + language);
  }
  InsertTaineeExamAttempt(empid: string, exam: string, type: string, language: string, data) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'InsertTaineeExamAttempt/' + empid + '/' + exam + '/' + type + '/' + language, data);
  }
  AddEmployeeProbation(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'JankariPortal/' + 'AddEmployeeProbation/' + empid, data);
  }
  CancelEmployeeLeave(id: string, empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'CancelEmployeeLeave/' + id + '/' + empid, data);
  }
  GetPromotionNotAprroveDetails(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetPromotionNotAprroveDetails?whr=' + btoa(where));
  }
  ApprovePramotion(empid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ApprovePramotion/' + empid, data);
  }
  AddEmployeeVisitWeb(userid: string, data): any {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddEmployeeVisitWeb/' + userid, data);
  }
  GetEmployeeAbsentList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeeAbsentList?whr=' + btoa(where));
  }
  AddAbsentEmployeeAttendance(updatedby: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddAbsentEmployeeAttendance/' + updatedby, data);
  }
  ApproveMultipleEmployeeLeave(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'ApproveMultipleEmployeeLeave/' + userid, data);
  }
  RejectedMultipleEmployeeLeave(userid: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'RejectedMultipleEmployeeLeave/' + userid, data);
  }
  GetRptAPPEmployeeAttendanceHolidayTL(empid: string) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptAPPEmployeeAttendanceHolidayTL/' + empid)
  }
  AddSancharakHoliday(empid: string, date: string, data: any) {
    return this.http.post(environment.apiUrl + 'Employee/' + 'AddSancharakHoliday/' + empid + '/' + date, data);
  }
  GetEmployeePromationList(where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetEmployeePromationList?whr=' + btoa(where));
  }

  FillDropDownUserwiseLite(us, ur, pgid, whr, col1, col2) {
    return this.http.get(environment.apiUrl + 'Center/' + 'FillDropDownUserwiseLite?us=' + us + '&ur=' + ur + '&pgid=' + pgid + '&whr=' + btoa(whr) + '&col1=' + btoa(col1) + '&col2=' + btoa(col2));
  }
  GetEmployeeDetailsforvisit(empid: string): any {
    return this.http.get(environment.apiUrl + 'JankariPortal/' + 'GetEmployeeDetailsforvisit/' + empid);
  }

  GetCertificateDetails(teacherid: string): any {
    return this.http.get(environment.DSapiUrl + 'Values/' + 'GetCertificateDetails/' + teacherid);
  }

  GetRptInterviewApplicationDetail2024(whr) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptInterviewApplicationDetail2024/?whr=' + btoa(whr));
  }
  GetRptSancharakAttendanceReport(userid: string, role: string, pgid: string, date: any, date1: any, where) {
    return this.http.get(environment.apiUrl + 'Employee/' + 'GetRptSancharakAttendanceReport/' + userid + '/' + role + '/' + pgid + '?date=' + date + '&date1=' + date1 + '&whr=' + btoa(where));
  }

  getAddress(latitude: number, longitude: number) {
    const apiKey = '66b5fa203ac9b554907512oda061714';
    const url = `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=${apiKey}`
    return this.http.get(url);
  }
}
